import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.svg"

const StyleguidePage = () => (
  <Layout>
    <SEO title="Home" />

    <header>
      <div>
        <a href="/">
          <img src={logo} className="logo" alt="Sedilia — Logotype" />
        </a>
        <span className="gutters">Makers Designers, Designers Makers</span>
      </div>

      <nav>
        <a href="/">Our Collection</a>
        <a href="/">Bespoke Services</a>
        <a href="/">Collaborations</a>
        <a href="/">The Journal</a>
        <a href="/">About Us</a>
        <a href="/">Contact Us</a>
      </nav>
    </header>

    <div className="padx2" style={{
      background: '#F9F9F4',
      paddingTop: '2.75rem'
    }}>
      <div style={{
        maxWidth: '27rem'
      }}>
        <p>Shown here upholstered in Tan Elegance Leather with Solid Oak Legs and a natural oil finish.</p>

        <p className="outer has-links">
          <a href="/">Explore options</a>
        </p>

        <h2 className="padx4">Facet Lounging Chair</h2>

        <p className="has-links">
          <strong>Two Seater Sofa</strong> Shown here upholstered in Tan Elegance Leather with Solid Oak Legs and a natural oil finish. <br />
          <em>£1249 (excluding fabrics)</em>
        </p>

        <div className="p1 has-links outerx2">
          <p>
            <strong>New Fabrics In</strong> we just received some new custom fabrics from our suppliers in Italy. With some longer copy to tell a little bit more about the progress of a new collection piece. <em><a href="/">Read More</a></em>
          </p>
          <h4 className="outerx2">
            <a href="/">The Journal</a>, September 15th 2019
          </h4>
        </div>

        <div className="md p2">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eget risus vitae massa semper aliquam quis mattis quam. Morbi vitae tortor tempus, placerat leo et, suscipit lectus. Phasellus ut euismod massa, eu eleifend ipsum. Nulla eu neque commodo, dapibus dolor eget, dictum arcu. In nec purus eu tellus consequat ultricies.</p>
          <p>Donec feugiat tempor turpis, rutrum sagittis mi venenatis at. Sed molestie lorem a blandit congue. Ut pellentesque odio quis leo volutpat, vitae vulputate felis condimentum.</p>
          <h3>Sub Headline</h3>
          <p>Praesent vulputate fermentum lorem, id rhoncus sem vehicula eu. Quisque ullamcorper, orci adipiscing auctor viverra, velit arcu malesuada metus, in volutpat tellus sem at justo.</p>
        </div>

        <div className="md p3">
          <h3>About us</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent eget risus vitae massa semper aliquam quis mattis quam. Morbi vitae tortor tempus, placerat leo et, suscipit lectus. Phasellus ut euismod massa, eu eleifend ipsum. Nulla eu neque commodo, dapibus dolor eget, dictum arcu. In nec purus eu tellus consequat ultricies.</p>
          <p>Donec feugiat tempor turpis, rutrum sagittis mi venenatis at. Sed molestie lorem a blandit congue. Ut pellentesque odio quis leo volutpat, vitae vulputate felis condimentum.</p>
        </div>

        <div className="padx4" style={{
          background: '#E4B556'
        }}>
          <a href="/" className="btn white">Continue Reading</a>
        </div>

        <div className="padx4">
          <a href="/" className="btn upcase">Enquire</a>
        </div>
      </div>

      <div className="padx4 align-center">
        <h1 className="upcase">The Journal</h1>
        <h1>Impressions of Making</h1>
      </div>
    </div>

  </Layout>
)

export default StyleguidePage
